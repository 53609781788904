<template>
  <div class="worker-char-view">
    <div class="child-page-title">
      <h3>施工人员关键指标</h3>
    </div>
    <div class="char-context">
      <div>
        <dv-water-level-pond :config="config" class="swt" />
        出勤率
      </div>
      <div>
        <dv-water-level-pond :config="config1" class="swt" />
        培训率
      </div>
      <div>
        <dv-water-level-pond :config="config2" class="swt" />
        特殊工种
      </div>
    </div>
  </div>
</template>
<script>
import { getPrjPerWorkerRate } from '@/api/personnel'
export default {
  name: 'WorkerChartView',
  data() {
    return {
      config: null,
      config1: null,
      config2: null
    }
  },
  mounted() {
    this.removeWorkerStat()
  },
  methods: {
    removeWorkerStat() {
      getPrjPerWorkerRate().then((res) => {
        const { data } = res
        this.config = {
          data: [Math.ceil(data.totalOneNum * 100)],
          shape: 'round',
          waveNum: 4,
          waveHeight: 16,
          colors: ['#2CCDBA']
        }
        this.config1 = {
          data: [Math.ceil(data.totalTwoNum * 100)],
          shape: 'round',
          waveNum: 4,
          waveHeight: 16,
          colors: ['#6165FD']
        }
        this.config2 = {
          data: [Math.ceil(data.totalThreeNum * 100)],
          shape: 'round',
          waveNum: 4,
          waveHeight: 16,
          colors: ['#2ACFFF']
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.worker-char-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.char-context {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 30px;
  > div {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 34px;
    opacity: 0.9;
    text-align: center;
    .swt {
      text-align: left;
      height: 150px;
      width: 140px;
    }
  }
}
::v-deep .dv-water-pond-level text {
  font-size: 21px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
}
</style>
