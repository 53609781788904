<template>
  <div class="x-funnel-echart">
    <div :id="id" :option="option" style="height: 310px" />
  </div>
</template>
<script>
import Highcharts from 'highcharts/highstock'
import HighchartsMore from 'highcharts/highcharts-more'
const funnel = require('highcharts/modules/funnel')
import { hasVal } from '@/utils/index'
HighchartsMore(Highcharts)
funnel(Highcharts)
export default {
  name: 'Funnel',
  props: {
    id: {
      type: String
    },
    valOptions: {
      type: Array,
      default: null
    },
    title: {
      type: String,
      default: '统计图'
    },
    serieName: {
      type: String,
      default: '统计图'
    }
  },
  data() {
    return {
      option: null
    }
  },
  watch: {
    valOptions(val) {
      this.load()
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    /** 数据初始化 */
    load() {
      if (!hasVal(this.valOptions)) return
      const _data = []
      let data1 = []
      for (var i = this.valOptions.length - 1; i >= 0; i--) {
        data1 = []
        data1.push(this.valOptions[i].text)
        data1.push(this.valOptions[i].value)
        _data.push(data1)
      }
      this.showFunnel(_data)
    },
    showFunnel(_data) {
      this.option = {
        chart: {
          type: 'funnel',
          marginRight: 100,
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          backgroundColor: ''
        },
        title: {
          text: this.title,
          x: -50,
          style: {
            fontSize: '20px',
            fontFamily: 'Microsoft YaHei',
            fontWeight: '300',
            color: '#FFFFFF'
          }
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              crop: false,
              overflow: 'none',
              formatter: function() {
                return this.point.name
              },
              softConnector: true,
              style: {
                fontWeight: 300,
                color: '#FFFFFF'
              }
            },
            neckWidth: '30%',
            neckHeight: '20%',
            width: '100%',
            height: '80%'
          }
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y}人</b>'
        },
        series: [
          {
            name: this.serieName,
            data: _data,
            colors: [
              '#f47270',
              '#1f7dd4',
              '#f4c841',
              '#2695a9',
              '#875dc1',
              '#1d7dd3'
            ]
          }
        ]
      }
      Highcharts.chart(this.id, this.option)
    }
  }
}
</script>
<style lang="scss" scoped>
.x-funnel-echart {
  height: 310px;
  width: 100%;
  position: relative;
}
</style>
