<template>
  <div class="home">
    <child-page-head :title="title" />
    <div class="home-top-main" />
    <!-- 方块组件 -->
    <drawer-personnel>
      <!-- 左侧内容-上 -->
      <div slot="leftDrawerTopContent">
        <left-drawer-top-content />
      </div>
      <!-- 左侧内容-中 -->
      <div slot="leftDrawerMiddleContent">
        <left-drawer-middle-content />
      </div>
      <!-- 左侧内容-下 -->
      <div slot="leftDrawerBottomContent">
        <left-drawe-bottom-content />
      </div>
      <!-- 中间部分-上 -->
      <div slot="MiddleDrawerTopContent">
        <middle-drawer-top-content />
      </div>
      <!-- 中间部分-中 -->
      <div slot="MiddleDrawerMiddleContent">
        <middle-drawer-middle-content />
      </div>
      <!-- 中间部分-下 -->
      <div slot="MiddleDrawerBottomContent">
        <middle-drawer-bottom-content />
      </div>
      <!-- 右侧内容-上 -->
      <div slot="rightDrawerTopContent">
        <right-drawer-content
          title="在场劳务人员"
          :type="0"
          :row-num="9"
          :height="490"
        />
      </div>
      <!-- 右侧内容-下 -->
      <div slot="rightDrawerBottomContent">
        <right-drawer-content title="在场管理人员" :type="1" :height="304" />
      </div>
    </drawer-personnel>
  </div>
</template>
<script>
import childPageHead from '@/components/child-page-head'
import drawerPersonnel from '@/components/drawer-personnel'
import leftDraweBottomContent from './components/left-drawe-bottom-content.vue'
import leftDrawerTopContent from './components/left-drawer-top-content.vue'
import leftDrawerMiddleContent from './components/left-drawer-middle-content.vue'
import MiddleDrawerTopContent from './components/middle-drawer-top-content.vue'
import MiddleDrawerMiddleContent from './components/middle-drawer-middle-content.vue'
import MiddleDrawerBottomContent from './components/middle-drawer-bottom-content.vue'
import RightDrawerContent from './components/right-drawer-content.vue'
export default {
  name: 'PersonnelHome',
  components: {
    drawerPersonnel,
    childPageHead,
    leftDrawerTopContent,
    leftDraweBottomContent,
    leftDrawerMiddleContent,
    MiddleDrawerTopContent,
    MiddleDrawerMiddleContent,
    MiddleDrawerBottomContent,
    RightDrawerContent
  },
  data() {
    return {
      title: '人员管理'
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  background: url(../../assets/image/body-bg.jpg) no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 1000px;
  zoom: 1;
  &-top-main {
    padding: 0 13px;
    display: flex;
    justify-content: space-between;
    height: "calc(100vh - 102px)";
  }
}
</style>
