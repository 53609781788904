<template>
  <div class="personnel-chart-view">
    <div class="charts">
      <div class="l">
        <pie-chart
          id="personnel-pie"
          :val-options="typeWorkOptions"
          title="在场工种"
          unit="人"
          serie-name="人数"
        />
      </div>
      <div class="r">
        <funnel-chart
          id="personnel-funnel"
          :val-options="ageOptions"
          title="年龄结构"
          serie-name="人数"
        />
      </div>
    </div>
    <div class="xcqk">
      <div>
        <div class="num">{{ totalOneNum }}</div>
        初级工人
      </div>
      <div>
        <div class="num">{{ totalTwoNum }}</div>
        老龄工人
      </div>
      <div class="sjjy">
        <div class="num">{{ totalThreeNum }}%</div>
        三级教育
      </div>
    </div>
  </div>
</template>
<script>
import { getPerWorkerChartStats, getPrjWorkerStats } from '@/api/personnel'
import pieChart from '@/components/chart/pieChart.vue'
import funnelChart from '@/components/chart/funnelChart.vue'
import { hasVal } from '@/utils/index'
export default {
  components: { pieChart, funnelChart },
  data() {
    return {
      typeWorkOptions: [],
      ageOptions: [],
      totalOneNum: 0,
      totalTwoNum: 0,
      totalThreeNum: 0
    }
  },
  mounted() {
    this.revomePerWorkerChartStats()
    this.revomePrjWorkerStats()
  },
  methods: {
    /**
     * 加载人员统计表数据
     */
    revomePerWorkerChartStats() {
      getPerWorkerChartStats().then((res) => {
        const { data } = res
        this.typeWorkOptions = hasVal(data.chartOneStats)
          ? data.chartOneStats
          : []
        this.ageOptions = hasVal(data.chartTwoStats) ? data.chartTwoStats : []
      })
    },
    revomePrjWorkerStats() {
      getPrjWorkerStats().then((res) => {
        const { data } = res
        this.totalOneNum = data.totalOneNum
        this.totalTwoNum = data.totalTwoNum
        this.totalThreeNum = Math.ceil(data.totalThreeNum * 100)
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.personnel-chart-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .charts {
    display: flex;
    margin-top: 10px;
    .l {
      flex: 1;
      text-align: center;
      span {
        margin-bottom: 10px;
        display: inline-block;
      }
    }
    .r {
      flex: 1;
      text-align: center;
      span {
        margin-bottom: 10px;
        display: inline-block;
      }
    }
  }
  .xcqk {
    width: 96%;
    height: 65px;
    margin-left: 10px;
    box-sizing: border-box;
    background: #1e4593;
    border-radius: 5px;
    display: flex;
    > div {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      opacity: 0.8;
      flex: 1;
      text-align: center;
      border-right: 1px dashed #0e77ca9e;
      > div {
        margin-top: 13px;
        font-size: 26px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #10caff;
        line-height: 18px;
      }
    }

    .sjjy {
      border-right: none;
    }
    .bor {
      box-sizing: border-box;
      border-left: 1px dashed #0e77ca9e;
      border-right: 1px dashed #0e77ca9e;
    }
  }
}
</style>
