<template>
  <div class="personnel-stat-view">
    <div class="child-page-title">
      <h3>劳务关键指标</h3>
    </div>
    <div class="stat-context">
      <div class="switch-title-tabs">
        <div :class="tab === 0 ? 'checked le' : 'le'" @click="onTabClick(0)">
          劳务人员
        </div>
        <div :class="tab === 1 ? 'checked ri' : 'ri'" @click="onTabClick(1)">
          管理人员
        </div>
      </div>
      <div v-if="tab === 0" class="context">
        <div>
          <dv-digital-flop
            :config="workerDoingConfig"
            class="dv-digital-flop"
          />
          现场
        </div>
        <div>
          <dv-digital-flop
            :config="workerEnteredConfig"
            class="dv-digital-flop"
          />
          在岗
        </div>
        <div>
          <dv-digital-flop
            :config="workerRegisteredConfig"
            class="dv-digital-flop"
          />
          登记
        </div>
        <div>
          <dv-digital-flop
            :config="workerExitedConfig"
            class="dv-digital-flop"
          />
          退场
        </div>
      </div>
      <div v-else class="context">
        <div>
          <dv-digital-flop
            :config="managerDoingConfig"
            class="dv-digital-flop"
          />
          现场
        </div>
        <div>
          <dv-digital-flop
            :config="managerEnteredConfig"
            class="dv-digital-flop"
          />
          在岗
        </div>
        <div>
          <dv-digital-flop
            :config="managerRegisteredConfig"
            class="dv-digital-flop"
          />
          登记
        </div>
        <div>
          <dv-digital-flop
            :config="managerExitedConfig"
            class="dv-digital-flop"
          />
          退场
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getLaborKeyPoint } from '@/api/personnel'
export default {
  name: 'PersonnelStatView',
  data() {
    return {
      tab: 0,
      workerDoingConfig: null,
      workerRegisteredConfig: null,
      workerEnteredConfig: null,
      workerExitedConfig: null,
      managerDoingConfig: null,
      managerRegisteredConfig: null,
      managerEnteredConfig: null,
      managerExitedConfig: null,
      style: {
        fill: '#fad400'
      }
    }
  },
  mounted() {
    this.removeWorkerKeyPoint()
    this.removeManagerKeyPoint()
  },
  methods: {
    /**
     * 获取施工人员劳务指标
     */
    removeWorkerKeyPoint() {
      getLaborKeyPoint(0).then((res) => {
        const { data } = res
        this.workerDoingConfig = {
          number: [data.isDoing],
          style: this.style
        }
        this.workerRegisteredConfig = {
          number: [data.registered],
          style: this.style
        }
        this.workerEnteredConfig = {
          number: [data.entered],
          style: this.style
        }
        this.workerExitedConfig = {
          number: [data.exited],
          style: this.style
        }
      })
    },
    /**
     * 获取管理员劳务指标
     */
    removeManagerKeyPoint() {
      getLaborKeyPoint(1).then((res) => {
        const { data } = res
        this.managerDoingConfig = {
          number: [data.isDoing],
          style: this.style
        }
        this.managerRegisteredConfig = {
          number: [data.registered],
          style: this.style
        }
        this.managerEnteredConfig = {
          number: [data.entered],
          style: this.style
        }
        this.managerExitedConfig = {
          number: [data.exited],
          style: this.style
        }
      })
    },
    /**
     * 切换tab
     */
    onTabClick(index) {
      this.tab = index
    }
  }
}
</script>
<style lang='scss' scoped>
.personnel-stat-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .stat-context {
    margin-top: 20px;
    .switch-title-tabs {
      display: flex;
      margin: 0 auto;
      align-items: center;
      width: 260px;
      margin-top: 16px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      line-height: 18px;
      > div {
        flex: 1;
        width: 129px;
        height: 27px;
        line-height: 27px;
        text-align: center;
        cursor: pointer !important;
        border: 1px solid #ffffff;
        opacity: 0.6;
        color: #ffffff;
      }
      .le {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
      }
      .ri {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }
      .checked {
        background-color: #064a9d;
        opacity: 1;
      }
    }
    .context {
      width: 96%;
      height: 65px;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      margin-top: 35px;
      .dv-digital-flop {
        height: 30px;
      }
      > div {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
        flex: 1;
        text-align: center;
        border-right: 1px dashed #0e77ca9e;
      }
    }
  }
}
</style>
