<template>
  <div class="personnel-stat-view">
    <div class="child-page-title">
      <h3>劳动力近7天出勤趋势</h3>
    </div>
    <div v-if="hasVal(attendanceForceTrendStat)" class="stat-charts-context">
      <line-chart
        id="personnel-line-middle-middle"
        :val-options="attendanceForceTrendStat"
        title=""
        y-axis-title="出勤趋势(人/天)"
        serie-name="人数"
      />
    </div>
    <div v-else class="stat-charts-context empty">暂无近7天劳动力出勤趋势</div>
  </div>
</template>
<script>
import { getAttendanceTrendStat } from '@/api/personnel'
import lineChart from '@/components/chart/lineChart.vue'
import { hasVal } from '@/utils/index'
export default {
  name: 'AttendanceForceTrendStat',
  components: { lineChart },
  data() {
    return {
      attendanceForceTrendStat: []
    }
  },
  mounted() {
    this.removeAttendanceTrendStat()
  },
  methods: {
    removeAttendanceTrendStat() {
      this.attendanceForceTrendStat = []
      getAttendanceTrendStat().then((res) => {
        const { data } = res
        if (hasVal(data.manager)) {
          this.attendanceForceTrendStat.push({
            name: '管理人员',
            data: data.manager,
            isAllData: false
          })
        }
        if (hasVal(data.worker)) {
          this.attendanceForceTrendStat.push({
            name: '劳务人员',
            data: data.worker,
            isAllData: false
          })
        }
      })
    },
    hasVal(val) {
      return hasVal(val)
    }
  }
}
</script>
<style lang='scss' scoped>
.personnel-stat-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .stat-charts-context {
    width: 99%;
    padding-left: 0.5%;
  }
}
</style>
