<template>
  <div class="attendance-list-view">
    <div class="child-page-title">
      <h3>{{ title }}</h3>
    </div>
    <div v-if="hasVal(config)" class="attendance-list-context">
      <dv-scroll-board :config="config" :style="'height:' + height + 'px'" />
    </div>
    <div v-else class="stat-charts-context empty">暂无{{ title }}</div>
  </div>
</template>
<script>
import { getAttendanceLatestList } from '@/api/personnel'
import { hasVal, parseTime } from '@/utils/index'
export default {
  name: 'AttendanceList',
  props: {
    title: {
      type: String,
      default: '人员考勤列表'
    },
    type: {
      type: Number,
      default: 0
    },
    rowNum: {
      type: Number,
      default: 5
    },
    height: {
      type: Number,
      default: 220
    }
  },
  data() {
    return {
      top: 50,
      config: null,
      header: [
        '照片',
        '姓名',
        '班组',
        '入场时间',
        '入场图',
        '出场时间',
        '出场图',
        '停留/H'
      ],
      columnWidth: [86, 85.75, 136.75, 137, 80, 137, 80, 80],
      aligns: [
        'center',
        'center',
        'center',
        'center',
        'center',
        'center',
        'center',
        'center'
      ],
      defaultAvatar: require('../../../assets/image/tab_icon_mine_default.png')
    }
  },
  mounted() {
    this.removeAttendanceList()
  },
  methods: {
    removeAttendanceList() {
      getAttendanceLatestList(this.top, this.type).then((res) => {
        const { data } = res
        const _data = []
        let _itemData = []
        if (hasVal(data.items)) {
          data.items.forEach((item) => {
            _itemData = []
            _itemData.push(
              "<img src='" +
                (hasVal(item.mugshot) ? item.mugshot : this.defaultAvatar) +
                "' />"
            )
            _itemData.push(item.name)
            _itemData.push(item.teamName)
            _itemData.push(parseTime(item.inTime, '{m}-{d} {h}:{i}:{s}'))
            _itemData.push(
              "<img style='height:80%;width:85%;margin-top:10%;' src='" +
                item.inPath +
                "' />"
            )
            _itemData.push(parseTime(item.outTime, '{m}-{d} {h}:{i}:{s}'))
            if (hasVal(item.outPath)) {
              _itemData.push(
                "<img style='height:80%;width:85%;margin-top:10%;' src='" +
                  item.outPath +
                  "' />"
              )
            } else {
              _itemData.push('')
            }
            _itemData.push(item.workingHour)
            _data.push(_itemData)
          })
          this.config = {
            header: this.header,
            columnWidth: this.columnWidth,
            align: this.aligns,
            data: _data,
            rowNum: this.rowNum,
            headerHeight: 50,
            headerBGC: 'rgba(0,255,255,.3)',
            evenRowBGC: '',
            oddRowBGC: 'rgba(0,255,255,.2)'
          }
        }
      })
    },
    hasVal(val) {
      return hasVal(val)
    }
  }
}
</script>
<style lang='scss' scoped>
.attendance-list-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .attendance-list-context {
    margin-top: 20px;
  }
}
</style>
