<template>
  <div class="personnel-stat-view">
    <div class="child-page-title">
      <h3>劳动工种近7天平均工作时长</h3>
    </div>
    <div v-if="hasVal(workTypeAverageWorkingTime)" class="stat-charts-context">
      <line-chart
        id="personnel-line-middle-bottom"
        :val-options="workTypeAverageWorkingTime"
        title=""
        y-axis-title="工种工作时长/H"
        serie-name="小时"
      />
    </div>
    <div v-else class="stat-charts-context empty">暂无近7天劳动工种平均工作时长趋势</div>
  </div>
</template>
<script>
import { getWorkTypeAverageWorkingTime } from '@/api/personnel'
import lineChart from '@/components/chart/lineChart.vue'
import { hasVal } from '@/utils/index'
export default {
  name: 'WorkTypeAverageWorkingTime',
  components: { lineChart },
  data() {
    return {
      workTypeAverageWorkingTime: []
    }
  },
  mounted() {
    this.removeWorkTypeAverageWorkingTime()
  },
  methods: {
    removeWorkTypeAverageWorkingTime() {
      this.workTypeAverageWorkingTime = []
      getWorkTypeAverageWorkingTime().then((res) => {
        const { data } = res
        if (hasVal(data.items)) {
          data.items.forEach((item) => {
            this.workTypeAverageWorkingTime.push({
              name: item.workTypeName,
              data: item.workingTime,
              isAllData: false
            })
          })
        }
      })
    },
    hasVal(val) {
      return hasVal(val)
    }
  }
}
</script>
<style lang='scss' scoped>
.personnel-stat-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .stat-charts-context {
    width: 99%;
    padding-left: 0.5%;
  }
}
</style>
